<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-header">
        <div class="header-left">
          <img v-if="oneModel.picture" :src="`${OSS_URL}${oneModel.picture}`" alt="" style="width: 11.9rem; height: 7.4rem" @click="handleClick(oneModel)" />
          <img v-else :src="`${xiaotouming}`" alt="" style="width: 11.9rem; height: 7.4rem" @click="handleClick(oneModel)" />
          <div class="ar" @click="handleClick(oneModel)">
            <span>{{ oneModel.name }}</span>
            <img src="@/assets/images/panoramic/ar.png" alt="" />
          </div>
        </div>
        <div class="header-right">
          <div class="header-right-container">
            <div class="header-right-picture" v-for="model in twoModels" :key="model.id">
              <img v-if="model.picture" :src="`${OSS_URL}${model.picture}`" style="width: 5.5rem; height: 3.4rem" alt="" @click="handleClick(model)" />
              <img v-else :src="`${xiaotouming}`" alt="" style="width: 5.65rem; height: 3.4rem" @click="handleClick(model)" />
              <div class="ar" @click="handleClick(model)">
                <span>{{ model.name }}</span>
                <img src="@/assets/images/panoramic/ar.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-footer">
        <div class="content-footer-picture" v-for="model in threeModels" :key="model.id">
          <img v-if="model.picture" :src="`${OSS_URL}${model.picture}`" alt="" style="width: 5.5rem; height: 3.4rem" @click="handleClick(model)" />
          <img v-else :src="`${xiaotouming}`" alt="" style="width: 5.65rem; height: 3.4rem" @click="handleClick(model)" />
          <div class="ar" @click="handleClick(model)">
            <span>{{ model.name }}</span>
            <img src="@/assets/images/panoramic/ar.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="5"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
import { API_MODULE } from '@/request/api'
import { openUrl } from '@/utils/windowOpen'
const xiaotouming = require('@/assets/images/xiaotouming.png')
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RED_PANORAMIC',
  components: {
    menuPop,
    navRouter,
    back
  },
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      icon: redIcon,
      xiaotouming: xiaotouming,
      oneModel: {},
      twoModels: [],
      threeModels: [],
      fourModels: []
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
    } else {
      this.icon = blueIcon
    }
    this.getData()

    // 保存路径
    this.setRouterList([{ name: '红色全景', path: this.$route.fullPath }])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
      } else {
        this.icon = blueIcon
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.GET(API_MODULE.SEARCH_CATEGORY_OR_CONTENT, { appId: this.appid })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.oneModel = res.data.model.slice(0, 1)[0]
            this.twoModels = res.data.model.slice(1, 5)
            this.threeModels = res.data.model.slice(5)
            // , 9);
            // this.fourModels = res.data.model.slice(9);
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClick(model) {
      if (model.externalLinksUrl) {
        openUrl(model.externalLinksUrl)
      } else {
        this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [model.id]
        })
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$router.push({
                path: `/layout/customize/${res.data.type}`,
                query: {
                  id: model.id,
                  music: model.music
                }
              })
              const path = `/layout/customize/${res.data.type}?id=${model.id}#music=${model.music}`
              this.useRouterList.push({
                name: model.name,
                path: path
              })
              this.setRouterList(this.useRouterList, path)
            } else {
              this.$message(res.msg)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;

      .router-name {
        font-size: 0.16rem;
        @include font_color('font-medium');
        margin-left: 0.1rem;
      }
    }
  }

  .content {
    /*min-height: calc(100vh - 140px);*/
    min-height: 13rem;
    padding-left: 0.6rem;
    padding-top: 0.6rem;
    box-sizing: border-box;
    @include background_color('background-medium');

    .ar {
      position: absolute;
      width: 4.5rem;

      border-radius: 0.1rem;
      background: rgba(0, 0, 0, 0.7);
      bottom: 0.6rem;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      font-size: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-left: 0.4rem;
      }
    }

    &-header {
      display: flex;

      .header-left {
        position: relative;

        .ar {
          left: 75%;
        }
      }

      .header-right {
        margin-left: 0.6rem;
        width: 12.5rem;

        &-container {
          display: flex;
          flex-wrap: wrap;

          .header-right-picture {
            margin-right: 0.6rem;
            position: relative;
          }
          .header-right-picture:nth-child(-n + 2) {
            margin-bottom: 0.6rem;
          }
        }
      }
    }

    .content-footer {
      display: flex;
      flex-wrap: wrap;
      .content-footer-picture {
        margin-right: 0.6rem;
        position: relative;
        margin-top: 0.6rem;
      }
    }
  }
}
</style>
